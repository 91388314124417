exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-client-hub-announcements-js": () => import("./../../../src/pages/client-hub/announcements.js" /* webpackChunkName: "component---src-pages-client-hub-announcements-js" */),
  "component---src-pages-client-hub-client-admin-dashboard-js": () => import("./../../../src/pages/client-hub/client-admin-dashboard.js" /* webpackChunkName: "component---src-pages-client-hub-client-admin-dashboard-js" */),
  "component---src-pages-client-hub-dashboard-js": () => import("./../../../src/pages/client-hub/dashboard.js" /* webpackChunkName: "component---src-pages-client-hub-dashboard-js" */),
  "component---src-pages-client-hub-faqs-js": () => import("./../../../src/pages/client-hub/faqs.js" /* webpackChunkName: "component---src-pages-client-hub-faqs-js" */),
  "component---src-pages-client-hub-get-in-touch-js": () => import("./../../../src/pages/client-hub/get-in-touch.js" /* webpackChunkName: "component---src-pages-client-hub-get-in-touch-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-intranet-announcements-js": () => import("./../../../src/pages/intranet/announcements.js" /* webpackChunkName: "component---src-pages-intranet-announcements-js" */),
  "component---src-pages-intranet-dashboard-js": () => import("./../../../src/pages/intranet/dashboard.js" /* webpackChunkName: "component---src-pages-intranet-dashboard-js" */),
  "component---src-pages-intranet-faqs-js": () => import("./../../../src/pages/intranet/faqs.js" /* webpackChunkName: "component---src-pages-intranet-faqs-js" */),
  "component---src-pages-intranet-get-in-touch-js": () => import("./../../../src/pages/intranet/get-in-touch.js" /* webpackChunkName: "component---src-pages-intranet-get-in-touch-js" */),
  "component---src-pages-intranet-premier-people-perks-js": () => import("./../../../src/pages/intranet/premier-people-perks.js" /* webpackChunkName: "component---src-pages-intranet-premier-people-perks-js" */),
  "component---src-pages-intranet-premier-training-academy-js": () => import("./../../../src/pages/intranet/premier-training-academy.js" /* webpackChunkName: "component---src-pages-intranet-premier-training-academy-js" */),
  "component---src-pages-intranet-system-help-js": () => import("./../../../src/pages/intranet/system-help.js" /* webpackChunkName: "component---src-pages-intranet-system-help-js" */),
  "component---src-pages-intranet-timegate-js": () => import("./../../../src/pages/intranet/timegate.js" /* webpackChunkName: "component---src-pages-intranet-timegate-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/case-study.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-industry-js": () => import("./../../../src/templates/industry.js" /* webpackChunkName: "component---src-templates-industry-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-policy-js": () => import("./../../../src/templates/policy.js" /* webpackChunkName: "component---src-templates-policy-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */)
}

